<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section section-pb48">
          <h2>О муниципальных услугах</h2>
          <div class="services_municipal services_faq services_faq-with-banners">
            <div class="services_municipal-content">
              <div class="services_list">
                <div v-for="(item, index) in servicesList" :key="index" class="title">
                  <a @click.prevent="setActive(index)" :class="{ active: activeTab === index }" href="">
                    {{ item }}
                  </a>
                </div>
              </div>
              <div class="services_municipal-description">
                <div v-for="(item, index) in servicesDescList" :key="index">
                  <div v-show="activeTab === index" class="item">
                    <div class="title">
                      {{ item.title }}
                    </div>
                    <div class="desc">
                      {{ item.desc }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Banners />
          </div>
        </section>
      </div>
    </div>
  </main>
</template>
<script>
import EditorJSComponent from "components/EditorJSComponent.vue";
import Banners from "@/views/services/components/Banners.vue";
export default {
  name: "ServciesMunicipal",

  async asyncData({ store }) {
    await store.dispatch("GET_SERVICES_ABOUT_PAGE");
  },
  components: {
    EditorJSComponent,
    Banners,
  },

  beforeRouteLeave(to, from, next) {
    this.$store.state.services_about_page = {};
    next();
  },

  data() {
    return {
      activeTab: 0,
      servicesList: [
        "1. Как найти нужную услугу на mkala.ru?",
        "2. Как получить услугу?",
        "3. Что такое предварительная запись?",
        "4. Зачем регистрироваться на mkala.ru?",
        "5. Как пользоваться личным кабинетом?",
      ],
      servicesDescList: [
        {
          title: "1. Как найти нужную услугу на mkala.ru?",
          desc: "Вся информация о госуслугах Махачкалы собрана на mkala.ru в каталоге «Услуги», где они разделены по основным сферам жизни горожанина: образование, здоровье, социальная поддержка, бизнес и другие. Самые востребованные из них, например, электронный дневник, запись к врачу, прием показаний водосчетчиков, получение и оплата ЕПД, поиск и оплата штрафов также можно найти в разделе «Популярное».",
        },
        {
          title: "2. Как получить услугу?",
          desc:
            "На mkala.ru сотни услуг и сервисов. В зависимости от того, что вам нужно, получить услугу вы можете:\n" +
            "только в электронном виде;\n" +
            "и в электронном виде, и офлайн;\n" +
            "только офлайн.\n" +
            "\n" +
            "Услуги, которые можно получить в электронном виде, в каталоге mkala.ru выделены серым прямоугольником. Результат по некоторым из них, например, резидентное парковочное разрешение вам предоставят также в электронном виде. В некоторых случаях в процессе проверки данных вас могут попросить представить дополнительные документы или посетить центр госуслуг «Мои документы» — в разделе «Заявки» в личном кабинете на mos.ru появится соответствующее сообщение.\n" +
            "\n" +
            "Услуги, которые можно получить офлайн, оказываются в центрах «Мои документы» и в профильных органах власти.\n" +
            "\n" +
            "Подробную информацию о том, как получить ту или иную услугу, вы можете найти на странице описания услуги или в инструкциях раздела «Инструкции для удобной жизни в Махачкале» на mkala.ru.",
        },
        {
          title: "3. Что такое предварительная запись?",
          desc: "Для получения некоторых услуг, например, для регистрации прав на недвижимое имущество, предоставления набора социальных услуг, оформления документов по земельным участкам и другим вы можете заранее записаться на прием в центр «Мои документы» или орган власти, который предоставляет эту услугу.",
        },
        {
          title: "4. Зачем регистрироваться на mkala.ru?",
          desc:
            "Регистрация нужна, чтобы получать услуги в электронном виде. Без регистрации вы можете посмотреть инструкции или справочную информацию об услугах. Для получения некоторых услуг, например, чтобы подать показания счетчиков воды, при регистрации достаточно указать ФИО, электронную почту и номер мобильного телефона.\n" +
            "\n" +
            "После того, как вы укажете свой СНИЛС в разделе «Мои документы» в личном кабинете, вам станет доступно значительно больше услуг: получение карты махачкалинца для студента, сведений из фонда капитального ремонта, прикрепление к поликлинике и другие.\n" +
            "\n" +
            "Чтобы получить доступ ко всем услугам на mkala.ru, нужна полная регистрация с подтверждением личности. Чтобы ее получить, достаточно обратиться к специалисту центра «Мои документы», предъявив паспорт и заполнив заявление с указанием СНИЛС.\n",
        },
        {
          title: "5. Как пользоваться личным кабинетом?",
          desc:
            "После регистрации каждый пользователь получает доступ в персональный раздел mkala.ru — личный кабинет, позволяющий:\n" +
            "получить доступ к наиболее интересным для себя госуслугам;\n" +
            "отслеживать ход оказания госуслуг;\n" +
            "подписаться на уведомления;\n" +
            "сохранять электронные квитанции о сделанных платежах.\n" +
            "\n" +
            "Получать госуслуги можно проще и быстрее — электронные формы будут заполняться автоматически на основании данных из профиля пользователя.\n",
        },
      ],
    };
  },

  methods: {
    setActive(tab) {
      this.activeTab = tab;
    },
  },

  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/services/index.styl"
</style>
